import './bootstrap';
import '../css/app.css';
import '../css/style.css';

import { createSSRApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import Badge from 'primevue/badge';
import OverlayBadge from 'primevue/overlaybadge';
import Aura from '@primevue/themes/aura';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Tooltip from 'primevue/tooltip';
import VueCookies from 'vue-cookies';

import './echo';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

const pinia = createPinia();
createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    return createSSRApp({ render: () => h(App, props) })
      .use(plugin)
      .use(ZiggyVue, Ziggy)
      .use(PrimeVue, {
        theme: {
          preset: Aura,
          options: {
            cssLayer: {
              name: 'primevue',
              order: 'tailwind-base, primevue, tailwind-utilities',
            },
          },
        },
      })
      .use(ToastService)
      .use(VueCookies)
      .use(pinia)
      .component('Badge', Badge)
      .component('Tooltip', Tooltip)
      .component('OverlayBadge', OverlayBadge)
      .component('Toast', Toast)
      .directive('tooltip', Tooltip)
      .mount(el);
  },
  progress: {
    color: '#4B5563',
  },
});
